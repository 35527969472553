.container {
    display: flex;
    align-items: center;
}

.link {
    color: #0D6EFD;
    text-decoration: underline;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    border: 1px solid transparent;
}

.text {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
}

.disabled {
    color: #6c757d;
    cursor: not-allowed;
    text-decoration: none;
}

.copyLinkBtn {
    margin-left: 5px;
    background: none;
    border: 1px solid #d9d9d9;
    padding: 6px 10px 6px 10px;
    border-radius: 0.375rem;
    visibility: hidden;
}

.copyLinkBtn:hover {
    background: #e3e3e3;
}

@media (max-width: 480px) {
    .text {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .link {
        padding: 0;
    }

    .link:hover {
        color: #0056b3;
        border: none;
        border-radius: 0;
    }

    .copyLinkIcon {
        display: none;
    }
}