.tableContainer {
    overflow: visible;
    width: 100%;
}

@media (max-width: 768px) {
    .table {
        font-size: 14px;
    }

    .tableCell {
        padding: 8px;
    }
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
}

.tableHeaderWrapper {
    background-color: #f9f9f9;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
}

.tableHeader th {
    padding: 16px;
    font-weight: bold;
    text-align: left;
}

.tableHeader th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.tableHeader th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: center;
}

.tableHeader th:nth-child(2),
.tableRow td:nth-child(2) {
    width: 60%;
}

.tableRow:nth-child(even) {
    transition: background-color 0.2s;
    background-color: #F9FAFB;
}

.tableRow:hover {
    background-color: #f6f6f6;
    border-radius: 8px;
}

.tableRow td {
    background-color: inherit;
    border: none;
}

.tableRow td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.tableRow td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.tableCell {
    padding: 16px;
    vertical-align: middle;
}

.headerIcon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
}

.headerText {
    vertical-align: middle;
    font-size: 12px;
    font-weight: bold;
    color: #6b7280;
}

.headerTextOnly {
    display: inline-block;
}

.table td {
    padding: 16px 8px;
    text-align: left;
}

.buttonGroup {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.actionButton {
    padding: 0 7px 0 7px;
}

.buttonIcon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.normalText {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #374151;
}

.emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
}

.emptyStateMessage {
    font-size: 1rem;
    color: #6c757d;
    margin: 1rem 0;
}

.emptyStateButton {
    margin-top: 1rem;
}

@media (max-width: 480px) {
    .table {
        font-size: 14px;
    }

    .tableCell {
        padding: 12px 8px;
    }

    .tableHeader th {
        padding: 8px;
    }

    .tableHeader th:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding-left: 16px;
    }

    .tableHeader th:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-right: 16px;
    }

    .tableRow td:last-child {
        text-align: center;
    }

    .table td {
        padding: 12px 8px;
        text-align: left;
    }

    .headerText {
        font-size: 11px;
    }

    .buttonGroup {
        display: table-cell;
    }

    .actionButton {
        margin-bottom: 4px;
    }

    .normalText {
        font-size: 10px;
        line-height: 18px;
    }
}