body {
  font-family: Lato, sans-serif !important;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,f8f8f8+59 */
  background: -moz-linear-gradient(-45deg, #ffffff 0%, #f8f8f8 59%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #ffffff 0%, #f8f8f8 59%); /* Chrome10-25,Safari5.1-6 */
  background: rgb(251, 251, 251, 1);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f8f8f8',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

html {
  scroll-padding-top: 150px; /* height of sticky header */
}

.body {
  margin-top: 10vh;
  padding-bottom: 60px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  height: 768px;
}

.body-bg {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,f8f8f8+59 */
  background: -moz-linear-gradient(-45deg, #ffffff 0%, #f8f8f8 59%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #ffffff 0%, #f8f8f8 59%); /* Chrome10-25,Safari5.1-6 */
  background: rgb(251, 251, 251, 1);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f8f8f8',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.flex {
  display: flex;
}

.flex-2 {
  flex: 2;
}

.App {
  text-align: center;
}

.logo {
  height: 120px;
}

.App-intro {
  font-size: large;
}

.action-btn {
  width: 100%;
}

.custom-table-row:hover .copy-domain-btn {
  visibility: visible;
}

.custom-table-row:hover .domain-cell {
  color: #0056b3;
  border: 1px solid #d9d9d9;
  border-radius: 0.375rem;
}

.domain-cell:hover {
  background: #ececec;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-link {
  border-radius: 0 !important;
  color: grey !important;
}

.nav-link.active {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  color: #007bff !important;
  background: none !important;
  border-bottom: solid 3px !important;
}

.input-domain {
  margin: auto;
  width: 100%;
  max-width: 460px;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 12px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.full-height {
  min-height: 100vh;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.placeholder-icon {
  margin: 0 10px 0 10px;
}

@media (max-width: 750px) {
  .full-height {
    height: unset;
  }
}

.bg-purple {
  background: #6e2f9a !important;
}

.bg-purple-light {
  background: #bc67ec !important;
}

.bg-purple-light-2 {
  background: #f8ecff !important;
}

.bg-grey {
  background: #1f1f1f;
}

.disabled-button-important {
  /* background-color: grey !important; */
  /* color: white !important; */
  opacity: 0.3 !important;
}

.bg-light-grey {
  background: #f6f6f6;
}

.bg-gradient-purple {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#dc89e8+0,b856d3+19,9040cc+100 */
  background: rgb(220, 137, 232); /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    rgb(220, 137, 232) 0%,
    rgba(184, 86, 211, 1) 19%,
    rgb(144, 64, 204) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    rgba(220, 137, 232, 1) 0%,
    rgba(184, 86, 211, 1) 19%,
    rgba(144, 64, 204, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    rgba(220, 137, 232, 1) 0%,
    rgba(184, 86, 211, 1) 19%,
    rgba(144, 64, 204, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dc89e8', endColorstr='#9040cc', GradientType=1); /* IE6-9 fallback on horizontal gradient */
}

.container {
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
}

.container-help {
  color: #333;
  text-align: left;
  margin: 50px auto 50px auto;
}

.answer {
  margin: 15px 0 15px 0;
}

.question {
  margin-top: 40px;
}

.left-pane {
  flex: 5;
  align-items: center;
  padding-top: 50px;
}

.right-pane {
  flex: 7;
  display: flex;
}

.sub-line {
  max-width: 350px;
  color: white;
  text-align: left;
  font-size: 26px;
  font-weight: 200;
}

.sub-header {
  text-align: left;
  margin-top: 50px;
  color: white;
  max-width: 350px;
  line-height: 30px;
}

.fs-normal {
  font-size: 16px;
}

.input-group {
  margin-top: 3px;
}

.box {
  background: white;
  border-radius: 8px;
  width: 320px;
  padding: 10px;
  margin: auto;
}

.recaptcha-terms {
  margin: 8px auto 0 auto;
  max-width: 600px;
  color: #dfdfdf;
  font-size: 12px;
}

.recaptcha-terms > a {
  color: #dfdfdf;
  text-decoration: underline;
}

.grecaptcha-badge {
  visibility: hidden;
}

.container-dropzone {
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
}

.validation-error {
  text-align: left;
  color: red;
  padding: 5px;
  font-size: 14px;
}

.icon {
  height: 40px;
  width: 50px;
  opacity: 0.2;
  padding: 5px;
  object-fit: contain;
}

.icon-example {
  height: 30px;
  padding: 5px;
  opacity: 0.8;
}

.example-box {
  padding: 5px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  text-align: left;
}

@media (max-width: 800px) {
  .button-tooltip {
    display: none;
  }
}

.faded {
  opacity: 0.4;
}

.faded:hover {
  opacity: 1;
}

.help {
  padding: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.faq {
  padding: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.nav-link-black {
  position: absolute;
  right: 0;
}

.nav-link-black > a {
  color: black;
  padding: 30px;
  text-decoration: underline;
}

.link {
  text-decoration: underline;
  color: #007bff !important;
  cursor: pointer;
}

.link-white {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.link-white:hover {
  text-decoration: underline;
  color: white;
}

.landing-info {
  margin-left: 100px;
}

.landing-pro-features {
  display: none;
}

.stage-success > .landing-info {
  display: none;
}

.stage-success > .landing-pro-features {
  display: block;
}

@media (max-width: 750px) {
  #root {
    height: unset;
  }

  .body {
    margin-top: unset;
    margin-bottom: unset;
    display: table;
    width: 100%;
  }

  .left-pane {
    display: table-footer-group;
  }

  .right-pane {
    display: table-header-group;
  }

  .logo {
    height: 100px;
  }

  .landing-info {
    display: block !important;
    padding: 15px;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .landing-pro-features {
    display: none !important;
  }
}

.container-white {
  background: rgba(255, 255, 255, 0.09);
  border-radius: 8px;
}

.btn:disabled {
  opacity: 0.3 !important;
  cursor: not-allowed;
}

.btn-purple {
  border: solid 2px #ab65d0;
  background-color: #ab65d0;
  color: white;
}

.btn-purple:hover {
  border: solid 2px #ab65d0;
  color: #ab65d0;
}

.btn-blue {
  border: solid 2px #6aa6ff;
  background-color: #6aa6ff;
  color: white;
}

.btn-blue:hover {
  border: solid 2px #4281d2;
  color: #4281d2;
}

.btn-black {
  border: solid 2px #000000;
  background-color: #000000;
  color: white;
}

.btn-black:hover {
  border: solid 2px #000000;
  color: #000000;
}

.btn-warning {
  border: solid 2px #ffc107;
  background-color: #ffc107;
  color: black;
}

.btn-warning:hover {
  border: solid 2px #ffc107;
  background-color: transparent;
  color: #ffc107;
}

.btn-upload,
.btn-upload:focus,
.btn-upload:active {
  box-shadow: 0 2px 20px 3px rgb(0 0 0 / 20%);
  border: solid 2px #ab65d0;
  background-color: #ab65d0;
  color: white;
  border-radius: 12px;
  padding: 8px 12px 8px 12px;
}

.btn-upload:hover {
  box-shadow: 0 2px 20px 3px rgb(0 0 0 / 20%);
  border: solid 2px #814c9d;
  background-color: #814c9d;
  color: white;
}

/*
    General
*/

.flex-1 {
  flex: 1;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
  color: #868686;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #bfbfbf;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.font-small {
  font-size: 13px !important;
}

.white {
  color: #ffffff !important;
}

.dark-grey {
  color: #333;
}

.dark-grey-2 {
  color: #4c4c4c;
}

.grey {
  color: #7d7d7d;
}

.light-grey {
  color: #9c9c9c;
}

.black {
  color: black;
}

.margin-10 {
  margin: 10px !important;
}

.margin-20 {
  margin: 20px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.mb-130 {
  margin-bottom: 100px !important;
}

.text-sm {
  font-size: 14px !important;
}

.text-blue {
  color: #6aa6ff;
}

.text-purple {
  color: #ab65d0;
}

.justify-center {
  justify-content: center !important;
}

.left-bold-white {
  color: white !important;
  text-align: left !important;
  font-weight: bold !important;
}

.left-white {
  color: white;
  text-align: left;
}

.bold-white {
  color: white;
  font-weight: bold;
}

.colorText {
  background: linear-gradient(90deg, #ae5dd7 60%, #0d6efd 90%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bold {
  font-weight: bold;
}

.display-none {
  display: none !important;
}

.mt-v8 {
  margin-top: 15vh !important;
}

.mt--3 {
  margin-top: -5vh !important;
}

.text-thin {
  font-weight: 200;
}

.cursor-pointer {
  cursor: pointer !important;
}

.color-purple {
  color: #6f388b !important;
}

.color-purple-2 {
  color: #6e2f9a;
}

.color-purple-light {
  color: #914ab5 !important;
}

.color-purple-light-2 {
  color: #ab65d0 !important;
}

.vh-80 {
  height: 80vh;
}

.vh-95 {
  height: 95vh;
}

.op-6 {
  opacity: 0.6;
}

.op-7 {
  opacity: 0.7;
}

.op-8 {
  opacity: 0.7;
}

.gap-7 {
  gap: 7em !important;
}

.height-50 {
  height: 50px;
}

.bg-light-red {
  background: #ffe2e2;
}

.round-top-0 {
  border-radius: 0 0 4px 4px !important;
}

.round-8 {
  border-radius: 8px;
}

.round-16 {
  border-radius: 16px;
}

.round-20 {
  border-radius: 20px;
}

.border-grey {
  border: solid 1px #d9d9d9f1;
}

.border-light-grey {
  border: solid 1px #f1f1f1f1;
}

@media (min-width: 750px) {
  .mt-lg-7 {
    margin-top: 7em;
  }
}

.p-32px {
  padding: 32px;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-26 {
  font-size: 26px !important;
}

.font-38 {
  font-size: 38px !important;
}

.font-47 {
  font-size: 47px !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.transparent-white-bg {
  background: rgba(255, 255, 255, 0.2);
}

.box-shadow {
  box-shadow: 0 2px 50px rgb(0 0 0 / 12%);
}
.indie-flower-font {
  font-family: 'Indie Flower', cursive;
}

.line-height-s {
  line-height: 1.3em;
}

.line-height-m {
  line-height: 1.8em;
}

.table-header {
  color: #9b9b9b;
  font-size: 12px;
  text-align: left;
}

.gradient-text {
  background: linear-gradient(97deg, #ae5dd7 0%, #0d6efd 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hide-scroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.transparent-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.copy-button {
  padding: 6px;
  background: #f5f5f5;
  border: none;
  border-radius: 8px;
}

.copy-button:hover {
  background: #e9e9e9;
}

.button {
  padding: 5px 15px 5px 15px;
  margin: 7px 5px 7px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 8px;
  color: #434343;
  background: #f6f6f6;
  cursor: pointer;
  border: none;
  font-family: Lato, sans-serif;
}

.button:hover {
  background: #6f388b !important;
}

.home-banner {
  background: #fafafa;
  padding: 8px;
  font-size: 14px;
  border-bottom: 1px solid #eaeaea;
  font-weight: bold;
}

.float-right {
  float: right;
}

.modalTitle {
  font-size: 1.5rem;
  color: #6f388b !important;
  font-weight: bold;
}

/*

    Cascading Modal Overrides (Boostrap)

 */

div[role='dialog'][aria-modal='true']:nth-last-child(1) {
  z-index: 1125 !important;
}
.modal-backdrop.show:nth-last-child(2) {
  z-index: 1100 !important;
}
div[role='dialog'][aria-modal='true']:nth-last-child(3) {
  z-index: 1075 !important;
}
.modal-backdrop.show:nth-last-child(4) {
  z-index: 1050 !important;
}
div[role='dialog'][aria-modal='true']:nth-last-child(5) {
  z-index: 1025 !important;
}
.modal-backdrop.show:nth-last-child(6) {
  z-index: 1000 !important;
}

/*

    Accordion Overrides (Boostrap)

 */

.accordion,
.accordion-button,
.accordion-button:not(.collapsed),
.accordion-item {
  background: none;
  width: 100%;
  font-size: 18px;
}

.accordion-body {
  color: #454d55;
}

.accordion-button:not(.collapsed) {
  color: black;
  font-weight: bold;
}

.track {
  -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.success_modal .modal-content {
  background-color: transparent !important;
  border: none;
}

.slide-left {
  -webkit-animation: slide-left 0.5s ease-out both;
  animation: slide-left 0.5s ease-out both;
}

.rounded-start {
  border-radius: 20px 0 0 20px !important;
}

@media (min-width: 768px) {
  /* lg breakpoint */
  .rounded {
    border-radius: 20px !important;
  }

  .rounded-end {
    border-radius: 0px 20px 20px 0px !important;
  }
}

/* You can add more breakpoints as needed, for example: */
@media (max-width: 768px) {
  /* md breakpoint */
  .rounded {
    border-radius: 20px !important;
  }

  .rounded-end {
    border-radius: 20px 20px 0 0 !important;
  }

  .slide-left {
    -webkit-animation: none;
    animation: none;
  }
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slide-left {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation flip-in-left
 * ----------------------------------------
 */

.flip-in-ver-left {
  -webkit-animation: flip-in-ver-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: flip-in-ver-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes flip-in-ver-left {
  0% {
    -webkit-transform: rotateY(80deg);
    transform: rotateY(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
}
@keyframes flip-in-ver-left {
  0% {
    -webkit-transform: rotateY(80deg);
    transform: rotateY(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    opacity: 1;
  }
}

.file-list {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
  margin-bottom: 16px;
}

/* Beautiful scrollbar suggested by Claude ✨ */
.file-list::-webkit-scrollbar {
  width: 8px;
}

.file-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.file-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.file-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}
