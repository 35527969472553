.container-manage {
    margin: 75px auto 0 auto;
}

.ad-container {
    max-width: 1000px;
    margin: auto;
    text-align: left;
    padding-left: 5px;
}

@media (max-width: 768px) {
    .container-manage {
        padding: 0%;
    }

    .container-manage-left-full h4{
        padding: 5%;
    }

    .container-manage-left-full .section ul {
        padding: 5%;
    }
}

.container-manage-left {
    width: 100%;
    grid-area: main;
    max-width: 1000px;
    margin: auto;
}

@media (min-width: 750px) {
    .container-manage-left {
        min-width: 600px;
    }
}

.container-manage-right {
    grid-area: info-1;
}

.info-box {
    width: 325px;
}

@media (max-width: 767px) {
    .info-box {
        width: 100%;
    }
}

@media (max-width: 540px) {
    .status{
        display: none;
    }
}

.trial-countdown {
    border: solid 1px #eaeaea;
    border-radius: 8px;
}

.container-manage-left-full {
    width: 100%;
    max-width: 1000px;
    margin: auto;
}

.container > * h4 > * h6 {
    margin-left: 10px;
    text-align: left;
}

.container > * h6 {
    font-size: 12px;
}

.container > * h4 {
    font-weight: bold;
    margin: 15px 0 15px 0;
}

.section {
    margin-top: 60px;
}

.section > * table {
    text-align: left;
    width: 100%;
    /* margin-bottom: 20px; */
}

.highlight {
    color: #007bff !important;
}

.sites-table {
    min-width: 500px;
    width: 100%;
}

.box-100 {
    background: white;
    border-radius: 20px;
    width: 100%;
    padding: 20px 0 20px 0;
    margin: auto;
    overflow-x: auto;
    box-shadow: 0 2px 50px rgb(0 0 0 / 12%);
}

.box-100-scrolless {
    background: white;
    border-radius: 24px;
    width: 100%;
    padding: 40px;
    margin: auto;
    position: relative;
}

@media (max-width: 1000px) {
    .box-100-scrolless {
        width: 111%;
    }
}

@media (max-width: 768px) {
    .box-100-scrolless {
        width: 100%;
        padding: 20px;
    }
}

@media (max-width: 480px) {
    .box-100-scrolless {
        width: 100%;
        padding: 0;
    }

}

.manage-section-title {
    padding: 0 10px 0 10px;
}

.live-site-limit {
    opacity: 0.7;
    margin-left: auto;
}

.table-domain {
    max-width: 200px;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-empty {
    padding: 30px 0 30px 0;
}

.action-icon {
    height: 20px;
    padding: 5px;
}

.carousel-dots > button {
    background: url(assets/icons/circle.svg) no-repeat;
    opacity: 0.6;
    border: none;
    height: 8px;
    width: 8px;
    margin-right: 5px;
}

.carousel-dots > button:disabled {
    opacity: 0.2;
}

.upgrade-time-card {
    border: solid 1px #cacaca;
    border-radius: 8px;
    padding: 2px 8px 2px 8px;
    font-size: 26px;
    font-weight: bold;
}

.upgrade-box {
    padding: 20px;
    border-radius: 20px;
    max-width: 700px;
}

.plans-modal > * .container-cards {
    min-width: 100% !important;
}

